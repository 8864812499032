<template>
	<v-sheet>
		<template>
			<render-content id="participantrecruitment"></render-content>
		</template>

        <h2 class="text-uppercase">Recruitment Workflow</h2>
		<image-row v-for="row in recruitmentworkflow" :key="row.id"  :image="row.image" :left="row.left">
			<template v-slot:text>
				<render-content :id="row.id"></render-content>
			</template>
		</image-row>
        <h2 class="text-uppercase">Information We Need From You</h2>
		<image-row v-for="row in information" :key="row.id"  :image="row.image" :left="row.left">
			<template v-slot:text>
				<render-content :id="row.id"></render-content>
			</template>
		</image-row>


	</v-sheet>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import ImageRow from "@c/ui/ImageRow";
export default {
	name: "ParticipantRecruitment",
	components: {
		RenderContent,
		ImageRow,
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
	data: () => {
		return {
            information: [
                
                {id: "fieldworkmethods", image: require(`@/assets/images/fieldwork_methods.png`)},
                {id: "screeninginfo", left: true, image: require(`@/assets/images/screening_information.png`)},
                {id: "timeframeavailability", image: require(`@/assets/images/timeframe_availability.png`)},
                {id: "prescreenmaterials", left: true, image: require(`@/assets/images/presession_materials.png`)},
                {id: "logistics", image: require(`@/assets/images/logistics.png`)},

            ],
			recruitmentworkflow: [
                {id: "kickoff", image: require(`@/assets/images/kick_off.png`), left: true},
                {id: "screening", image: require(`@/assets/images/screening.png`)},
                {id: "scheduling", left: true, image: require(`@/assets/images/scheduling.png`)},
                {id: "consentspretasks", image: require(`@/assets/images/consents_pretasks.png`)},
                {id: "appointmentconfirmation", left: true, image: require(`@/assets/images/confirmations.png`)},
                {id: "reportingupdates", image: require(`@/assets/images/reporting_updates.png`)},
                {id: "reminders", left: true, image: require(`@/assets/images/reminders.png`)},
                {id: "researchsession", image: require(`@/assets/images/research_sessions.png`)},
                {id: "confirmsessioncomplete", left: true, image: require(`@/assets/images/confirm_session_complete.png`)},
                {id: "incentivepaid", image: require(`@/assets/images/incentive_paid.png`)},
                {id: "qualitycontrolsurvey", left: true, image: require(`@/assets/images/quality_control.png`)},
            ],
		};
	},
};
</script>
